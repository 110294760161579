import React from 'react'
import styled from 'styled-components'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'

import GlobalStyle from '../components/css/globalStyle'
import Cta from '../components/common/cta'
import media from '../components/css/media'

import logo from '../images/kto-logo.png'
import notFoundBg from '../images/404.gif'

const Wrapper = styled.div`
  background-color: #000;
  height: 100vh;
`

const NotFound = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 2rem;

  img {
    margin: 0 auto;
    width: 100%;

    &.kto-logo {
      width: 140px;
    }
  }

  ${media.tablet`
    padding: 0;

    img {
      width: 50%;
    }
  `};
`

const H1 = styled.h1`
  font-weight: 600;
  color: #fff;
  width: 100%;
  font-size: 1.5em;
  margin: 2rem auto;

  ${media.tablet`
    width: 600px;
  `};
`

const ButtonWrapper = styled.div`
  text-align: center;
`

const NotFoundPage = () => {
  const intl = useIntl()
  return (
    <Wrapper>
      <GlobalStyle />
      <NotFound>
        <img src={notFoundBg} alt='KTO.com' width={600} loading='lazy' />

        <img
          src={logo}
          alt='KTO.com'
          width={140}
          loading='lazy'
          className='kto-logo'
        />

        <H1>
          <FormattedMessage id='errors.404' />
        </H1>

        <ButtonWrapper>
          <Cta
            url='/'
            title={intl.formatMessage({ id: 'common.takeMeHome' })}
            bgcolor='#31c27c'
            padding='.5rem 1rem'
          />
        </ButtonWrapper>
      </NotFound>
    </Wrapper>
  )
}

export default NotFoundPage
